<template>
  <v-navigation-drawer
    :value="drawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/favicon.png')"
          max-height="40px"
          max-width="40px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            Flower Campings
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-section-title title="FLOWER"></nav-menu-section-title>
      <nav-menu-link
        title="Campings"
        :to="{ name: 'campingList' }"
        :icon="icons.mdiTent"
      ></nav-menu-link>
      <nav-menu-link
        title="Hébergements"
        :to="{ name: 'rentingList' }"
        :icon="icons.mdiRvTruck"
      ></nav-menu-link>
      <nav-menu-link
        title="Services"
        :to="{ name: 'serviceCategoryList' }"
        :icon="icons.mdiPool"
      ></nav-menu-link>
      <nav-menu-group
        title="Offres spéciales"
        :icon="icons.mdiTicketPercentOutline"
      >
        <nav-menu-link
          title="Offres injectées"
          :to="{ name: 'specialOfferList' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Offres personnalisées"
          :to="{ name: 'specialOfferPersonalizedList' }"
        ></nav-menu-link>
      </nav-menu-group>
      <nav-menu-link
        title="Idées séjours"
        :to="{ name: 'thematicList' }"
        :icon="icons.mdiStackOverflow"
      ></nav-menu-link>
      <nav-menu-group
        title="Localisations"
        :icon="icons.mdiCompassRose"
      >
        <nav-menu-link
          title="Régions Catalogue"
          :to="{ name: 'regionCatalogueList' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Régions Web"
          :to="{ name: 'regionWebList' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Régions SEO"
          :to="{ name: 'regionSeoList' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Hébergements"
          :to="{ name: 'locationRentingList' }"
        ></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group
        title="Accueil"
        :icon="icons.mdiHomeVariantOutline"
      >
        <nav-menu-link
          title="Slider"
          locale="fr"
          :to="{ name: 'slideList', params: { locale: 'fr' } }"
        ></nav-menu-link>
        <nav-menu-link
          title="Slider"
          locale="gb"
          :to="{ name: 'slideList', params: { locale: 'en' } }"
        ></nav-menu-link>
        <nav-menu-link
          title="Slider"
          locale="nl"
          :to="{ name: 'slideList', params: { locale: 'nl' } }"
        ></nav-menu-link>
        <nav-menu-link
          title="Slider"
          locale="de"
          :to="{ name: 'slideList', params: { locale: 'de' } }"
        ></nav-menu-link>
        <nav-menu-link
          title="Selection"
          :to="{ name: 'selectionDetails' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Post réseaux sociaux"
          :to="{ name: 'socialNetworkPostList' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Nuage de liens"
          :to="{ name: 'linkCloudDetails' }"
        ></nav-menu-link>
      </nav-menu-group>
      <nav-menu-link
        title="Traductions"
        :to="{ name: 'translationList'}"
        :icon="icons.mdiTranslate"
      ></nav-menu-link>
      <nav-menu-group
        title="Editorial"
        :icon="icons.mdiPostOutline"
      >
        <nav-menu-link
          title="Pages"
          :to="{ name: 'pageList' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Blocks"
          :to="{ name: 'blockList' }"
        ></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group
        title="SEO"
        :icon="icons.mdiRocketLaunchOutline"
      >
        <nav-menu-link
          title="Metadonnées"
          :to="{ name: 'metadataList' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Paramètres"
          :to="{ name: 'metadataParameterList' }"
        ></nav-menu-link>
      </nav-menu-group>
      <nav-menu-link
        title="Moteurs"
        :to="{ name: 'engineList' }"
        :icon="icons.mdiCogOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Comités Entreprise"
        :to="{ name: 'worksCouncilList' }"
        :icon="icons.mdiBankOutline"
      ></nav-menu-link>
      <nav-menu-group
        title="Contacts"
        :icon="icons.mdiMessageQuestionOutline"
      >
        <nav-menu-link
          title="Comités Entreprise"
          :to="{ name: 'contactWorksCouncilList' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Fiche Groupes"
          :to="{ name: 'contactGroupFormSheetList' }"
        ></nav-menu-link>
      </nav-menu-group>
      <nav-menu-link
        title="Utilisateurs"
        :to="{ name: 'userList'}"
        :icon="icons.mdiAccountDetailsOutline"
      ></nav-menu-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiBookshelf,
  mdiPostOutline,
  mdiChatQuestion,
  mdiAccountDetailsOutline,
  mdiTranslate,
  mdiRocketLaunchOutline,
  mdiTent,
  mdiEarth,
  mdiCogOutline,
  mdiTicketPercentOutline,
  mdiRvTruck,
  mdiStackOverflow,
  mdiPool,
  mdiBankOutline,
  mdiCompassRose,
  mdiMessageQuestionOutline,
  mdiHomeVariantOutline
} from '@mdi/js'
import NavMenuSectionTitle from '../../components/LeftMenu/NavMenuSectionTitle.vue'
import NavMenuLink from '../../components/LeftMenu/NavMenuLink.vue'
import NavMenuGroup from '../../components/LeftMenu/NavMenuGroup.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuLink,
    NavMenuGroup
  },
  data: () => ({
    icons: {
      mdiHomeOutline,
      mdiAlphaTBoxOutline,
      mdiBookshelf,
      mdiPostOutline,
      mdiChatQuestion,
      mdiAccountDetailsOutline,
      mdiTranslate,
      mdiRocketLaunchOutline,
      mdiTent,
      mdiEarth,
      mdiCogOutline,
      mdiTicketPercentOutline,
      mdiRvTruck,
      mdiStackOverflow,
      mdiPool,
      mdiBankOutline,
      mdiCompassRose,
      mdiMessageQuestionOutline,
      mdiHomeVariantOutline
    }
  }),
  computed: {
    drawerOpen () {
      return this.$store.getters.isDrawerOpen
    }
  }
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
